import React from "react";
import type { GatsbyBrowser } from "gatsby";
import { store } from "./state/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";

const RootWrapper: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
};

export default RootWrapper;
