import { FormInfo } from "./types";
import { Reducer } from "redux";
export interface InitialState {
  showLoginModal: boolean;
  formProgress: number;
  stripeClientSecret?: string;
  formInfo: FormInfo;
  signedIn: boolean;
}

const initialFormInfo: FormInfo = {
  patientFirstName: "",
  patientLastName: "",
  patientAge: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  city: "",
  country: "",
  clinicalTrial: [],
  message: "",
};

const initialState: InitialState = {
  showLoginModal: true,
  formProgress: 0,
  formInfo: initialFormInfo,
  signedIn: false,
};

export enum ActionTypes {
  SetStripeClientSecret = "SET_STRIPE_CLIENT_SECRET",
  ResetStripeClientSecret = "RESET_STRIPE_CLIENT_SECRET",
  ShowLoginModal = "SHOW_LOGIN_MODAL",
  FormProgress = "FORM_PROGRESS",
  ReduceFormProgress = "REDUCE_FORM_PROGRESS",
  IncreaseFormProgress = "INCREASE_FORM_PROGRESS",
  ResetFormProgress = "RESET_FORM_PROGRESS",
  SetFormInfo = "SET_FORM_INFO",
  ResetFormInfo = "RESET_FORM_INFO",
  SetFormProgress = "SET_FORM_PROGRESS",
  SetSignedIn = "SET_SIGNED_IN",
}

export type SetStripeClientSecretAction = {
  type: ActionTypes.SetStripeClientSecret;
  stripeClientSecret: string;
};

export type ResetStripeClientSecretAction = {
  type: ActionTypes.ResetStripeClientSecret;
};

export type ShowLoginModalAction = {
  type: ActionTypes.ShowLoginModal;
  showLoginModal: boolean;
};

export type FormProgressAction = {
  type: ActionTypes.FormProgress;
  formProgress: number;
};

export type ReduceFormProgressAction = {
  type: ActionTypes.ReduceFormProgress;
};

export type IncreaseFormProgressAction = {
  type: ActionTypes.IncreaseFormProgress;
};

export type ResetFormProgressAction = {
  type: ActionTypes.ResetFormProgress;
};

export type SetFormInfoAction = {
  type: ActionTypes.SetFormInfo;
  formInfo: FormInfo;
};

export type ResetFormInfoAction = {
  type: ActionTypes.ResetFormInfo;
};

export type SetFormProgressAction = {
  type: ActionTypes.SetFormProgress;
  formProgress: number;
};

export type SetSignedInAction = {
  type: ActionTypes.SetSignedIn;
  signedIn: boolean;
};

type AllActions =
  | SetStripeClientSecretAction
  | ResetStripeClientSecretAction
  | ShowLoginModalAction
  | FormProgressAction
  | ReduceFormProgressAction
  | IncreaseFormProgressAction
  | ResetFormProgressAction
  | SetFormInfoAction
  | ResetFormInfoAction
  | SetFormProgressAction
  | SetStripeClientSecretAction
  | ShowLoginModalAction
  | FormProgressAction
  | ReduceFormProgressAction
  | IncreaseFormProgressAction
  | ResetFormProgressAction
  | SetFormInfoAction
  | ResetFormInfoAction
  | SetFormProgressAction
  | SetSignedInAction;

const appReducer: Reducer<InitialState, AllActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SHOW_LOGIN_MODAL":
      return {
        ...state,
        showLoginModal: action.showLoginModal,
      };

    case "FORM_PROGRESS":
      return {
        ...state,
        formProgress: action.formProgress,
      };

    case "REDUCE_FORM_PROGRESS":
      return {
        ...state,
        formProgress: state.formProgress - 1,
      };

    case "INCREASE_FORM_PROGRESS":
      return {
        ...state,
        formProgress: state.formProgress + 1,
      };

    case "RESET_FORM_PROGRESS":
      return {
        ...state,
        formProgress: 0,
      };

    case "SET_FORM_PROGRESS":
      return {
        ...state,
        formProgress: action.formProgress,
      };

    case "SET_FORM_INFO":
      return {
        ...state,
        formInfo: action.formInfo,
      };

    case "RESET_FORM_INFO":
      return {
        ...state,
        formInfo: initialFormInfo,
      };

    case "SET_STRIPE_CLIENT_SECRET":
      return {
        ...state,
        stripeClientSecret: action.stripeClientSecret,
      };

    case "RESET_STRIPE_CLIENT_SECRET":
      return {
        ...state,
        stripeClientSecret: undefined,
      };

    case "SET_SIGNED_IN":
      return {
        ...state,
        signedIn: action.signedIn,
      };

    default:
      return state;
  }
};

export default appReducer;
